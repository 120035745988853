<template>
  <v-select
    solo
    flat
    dense
    class="select-filter"
    background-color="background"
    :items="sortOptions"
    :value="value"
    @change="handleSortFilter"
    hide-details
  />
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
export default {
  name: "ProductSortFilter",
  data() {
    return {
      value: "",
      sortOptions: [
        { value: "", text: "Rilevanza", icon: "ion-arrow-up-c" },
        { value: "pri_asc", text: "Prezzo crescente", icon: "ion-arrow-up-c" },
        {
          value: "pri_des",
          text: "Prezzo decrescente",
          icon: "ion-arrow-down-c"
        },
        {
          value: "pri_um_asc",
          text: "Prezzo al kg crescente",
          icon: "ion-arrow-down-c"
        },
        {
          value: "pri_um_des",
          text: "Prezzo al kg decrescente",
          icon: "ion-arrow-down-c"
        },
        {
          value: "alf_asc",
          text: "Alfabetico crescente",
          icon: "ion-arrow-up-c"
        }
      ]
    };
  },
  props: ["sortFilter"],
  methods: {
    handleSortFilter(sort) {
      this.$emit("handleSortFilter", sort);
    }
  },
  mounted() {
    if (this.sortFilter) {
      this.value = cloneDeep(this.sortFilter);
    }
  }
};
</script>

<style scoped lang="scss">
.select-filter {
  // max-width: 250px;
}
</style>
