<template>
  <div>
    <v-expansion-panels multiple flat v-model="panel">
      <v-expansion-panel v-for="facet in facetsFiltered" :key="facet.name">
        <v-expansion-panel-header
          class="panel-header text-body-1 primary--text text--lighten-1 pa-0"
        >
          <b>{{ facet.name }}</b>
          <span
            class="grey--text caption"
            v-if="
              facetsFilter[facet.parameterName] &&
                facetsFilter[facet.parameterName].length > 0
            "
          >
            (+{{ facetsFilter[facet.parameterName].length }} filtri)
          </span>
          <!-- <template v-slot:actions>
            <v-tooltip
              left
              v-if="
                facetsFilter[facet.parameterName] &&
                  facetsFilter[facet.parameterName].length > 0
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click.prevent.stop="clearFacets(facet.parameterName)"
                >
                  <v-icon style="font-style:normal" small>x</v-icon>
                </v-btn>
              </template>
              Rimuovi i filtri per {{ facet.name }}
            </v-tooltip>
          </template> -->
        </v-expansion-panel-header>
        <v-expansion-panel-content class="panel-content">
          <filtered-chebox-list
            :facet="facet"
            :facetsFilter="facetsFilter"
            @handleFacetFilterMobile="handleFacetFilterMobile"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<style global lang="scss">
@import "@/scss/variables.scss";
.panel-header {
  background-color: var(--v-background-base);
  padding: 0;
  text-transform: uppercase;
  min-height: 32px !important;
  font-size: 13px;
  border-bottom: 1px solid var(--v-grey-lighten2);
  border-radius: 0 !important;
  .v-expansion-panel-header__icon {
    i {
      color: var(--v-primary-lighten-1) !important;
      font-size: 35px;
      width: 20px;
    }
  }
}
.panel-content {
  background-color: var(--v-background-base);
  .v-expansion-panel-content__wrap {
    padding: 0;
  }
  .panel-checkbox {
    margin-top: 0;
    .v-label {
      font-size: 13px !important;
    }
  }
}
</style>
<script>
import FilteredCheboxList from "./FilteredCheboxList.vue";

export default {
  components: { FilteredCheboxList },
  name: "ProductFacetFilterMobile",
  data() {
    return {
      dialog: false,
      panel: [0, 1, 2, 3],
      panelLength: [6, 6, 6, 6],
      loadingApplyButton: false,
      initialFacets: {}
    };
  },
  props: {
    facets: { type: Array },
    facetsFilter: { type: Object },
    filteredProductsCount: { type: Number, required: false },
    hideFilterString: { type: String, default: "" }
  },
  computed: {
    facetsFiltered() {
      let vm = this;
      if (vm.facets) {
        return vm.facets.filter(
          item => vm.hideFilterString.indexOf(item.name) < 0
        );
      } else {
        return {};
      }
    }
  },
  methods: {
    handleFacetFilterMobile(param) {
      this.$emit("handleFacetFilterMobile", param);
      this.loadingApplyButton = true;
    }
  }
};
</script>

<style></style>
